import { Amplify, API } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import awsExports from './aws-exports'
//skeleton pr
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { MainRoutes } from './routes/MainRoutes'
import { components } from './layout/MainLayout'
import { AppTypes } from './types'
import '@fontsource/ubuntu'
import { I18n } from 'aws-amplify'
import { translations } from '@aws-amplify/ui-react'

I18n.putVocabularies(translations)
I18n.setLanguage('fr')

I18n.putVocabularies({
    fr: {
        'Sign In': 'Se connecter',
        'Sign Up': "S'inscrire",
        'Enter your Password': 'Saisissez votre mot de passe',
        'Current Password': 'Mot de passe actuel',
        'Update password': 'Mettre à jour le mot de passe',
    },
    es: {
        'Sign In': 'Registrarse',
        'Sign Up': 'Regístrate',
    },
})

Amplify.configure(awsExports)
API.configure({
    // url api was written in hard in the code so it doenst work
    aws_appsync_graphqlEndpoint: import.meta.env.VITE_REACT_APP_APPSYNC_API_ENDPOINT!,
    aws_appsync_region: 'eu-west-3',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: '',
})

function App({ signOut, user }: AppTypes) {
    return (
        <BrowserRouter>
            <MainRoutes signOut={signOut} user={user} />
        </BrowserRouter>
    )
}

// eslint-disable-next-line react-refresh/only-export-components
export default withAuthenticator(App, { hideSignUp: true, components })
