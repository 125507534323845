import { Backdrop, Box, Chip, CircularProgress, Grid,Card as CRD } from '@mui/material'
import { updateIntegrationStatus } from '../utils'
import { useEffect, useMemo, useState } from 'react'
import { useUserContext } from '../contexts/NavigationContext'
import { API } from 'aws-amplify'
import {
    Alert,
    Button,
    Card,
    CopyField,
    JsonDisplay,
    Payload,
} from '@killbillsdev/killbills-ui/lib/components/atoms/partnersAppAtoms';
import   {ReceiptReceived, Tabs}  from '@killbillsdev/killbills-ui/lib/components/'
import { GridPanelRow } from '../layout/GridPanelRow'
import { PageTitle, SubTitle } from '../layout/Typographies'
import { GridPage } from '../layout/GridPage'

export const SendFirstReceiptPage = () => {
    const { navigate, groups, updateUserContext,hmac } =
        useUserContext()
    const [firstReceiptReceived, setFirstReceiptReceived] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showTestPayload, _setShowTestPayload] = useState(false)
    const [actualMenu, setActualMenu] = useState('instructions')
    const [payloads, setPayloads] = useState<any>([])
    const [receiptReceived, setReceiptReceived]: any = useState({})
    const [ isLoading, setIsLoading ] = useState(false);
    const [ payloadNotSent, setPayloadNotSent ]:any = useState(false);
    const [ receiptNotReceived, setReceiptNotReceived ]:any = useState(false);
    const [ selected,setSelected ] = useState(-1);
    const [ errorPayloadToShow,setErrorPayloadToShow ] = useState();
    const [ errorReportToShow,setErrorReportToShow ] = useState();
    const [ errorPayloadView ,setErrorPayloadView] = useState(false);

    const partner = useMemo(
        () => (groups || []).filter((g) => !['pos', 'bank'].includes(g))[0],
        [groups]
    )

    const tabs:{text:string;number:any;id:string, hideNumber?:boolean}[] = [
        {
            text: 'Instructions',
            number: 1,
            id: 'instructions',
            hideNumber:true
        },
        {
            text: 'Payloads envoyées',
            number: payloads?.length || 0,
            id: 'payloads',
        },
    ]

    const testPayload = {
        reference_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        amount: Math.floor(Math.random() * 1000),
        total_tax_amount: 180,
        currency: 'EUR',
        date: new Date(),
        covers: 2,
        table: '1',
        invoice: 1,
        total_discount: 0,
        mode: '0',
        merchant: {
            merchant_name: 'POS merchant name',
            reference_id: 'uuid',
            merchant_id: 1234567,
        },
        store: {
            reference_id: 'uuid',
            billing_descriptor: 'POS billing descriptor',
            store_name: 'POS store name',
            address: {
                full_address: '3 avenue de la Gloire, 75010 Paris',
                number: 3,
                street_address: 'avenue de la Gloire',
                city: 'Paris',
                postal_code: 75010,
                country: 'France',
            },
            siret: '12345678910111',
            code_ape: '4711D',
            tva_intra: 'FR53157896342',
        },
        "items": [
            {
                "name": "Salade Cesar 1 ",
                "description": "",
                "quantity": 1,
                "price": 850,
                "reference_id": "1c49ad5c-2610-4bd7-bbb5-e235639a0a42",
                "total_amount": 850,
                "tax": {
                    "description": "TVA",
                    "amount": 85,
                    "rate": 1000
                },
                "sub_items": [
                    {
                        "name": "Priced ITEM",
                        "description": "",
                        "quantity": 1,
                        "reference_id": "5df1e0fa-3bdc-461a-9170-a74bb2f0792b",
                        "total_amount": 300,
                        "type": "dish",
                        "price": 1555,
                        "tax": {
                            "description": "TVA",
                            "amount": 30,
                            "rate": 1000
                        }
                    },
                    {
                        "name": "Saucisses",
                        "description": "",
                        "quantity": 1,
                        "reference_id": "d15e20c6-925c-491a-8381-153c9352aadd",
                        "total_amount": 400,
                        "type": "dish",
                        "tax": {
                            "description": "TVA",
                            "amount": 40,
                            "rate": 1000
                        }
                    },
                    {
                        "name": "Thé",
                        "description": "",
                        "quantity": 1,
                        "reference_id": "72b2479f-9210-44fc-8187-a4f40bc31ee6",
                        "total_amount": 250,
                        "type": "dish",
                        "tax": {
                            "description": "TVA",
                            "amount": 25,
                            "rate": 1000
                        }
                    }
                ]
            },
            {
                "name": "Petit-déjeuner anglais 1",
                "description": "",
                "quantity": 1,
                "price": 950,
                "reference_id": "1c49adnn5c-2610-4bd7-bbb5-e235639a0a42",
                "total_amount": 950,
                "tax": {
                    "description": "TVA",
                    "amount": 190,
                    "rate": 2000
                },
                "sub_items": [
                    {
                        "name": "Oeufs brouillés test",
                        "description": "",
                        "quantity": 1,
                        "reference_id": "5df1e0fa-3bdc-461a-9170-a74bb2f0792b",
                        "total_amount": 300,
                        "type": "dish",
                        "tax": {
                            "description": "TVA",
                            "amount": 30,
                            "rate": 1000
                        }
                    },
                    {
                        "name": "Saucisses test ",
                        "description": "",
                        "quantity": 1,
                        "reference_id": "d15e20c6-925c-491a-8381-153c9352aadd",
                        "total_amount": 400,
                        "type": "dish",
                        "tax": {
                            "description": "TVA",
                            "amount": 40,
                            "rate": 1000
                        }
                    },
                    {
                        "name": "Thé test ",
                        "description": "",
                        "quantity": 1,
                        "reference_id": "72b2479f-9210-44fc-8187-a4f40bc31ee6",
                        "total_amount": 250,
                        "type": "dish",
                        "tax": {
                            "description": "TVA",
                            "amount": 25,
                            "rate": 1000
                        }
                    }
                ]
            }
        ],
        partner_name: partner,
    }

    const loadPayloads = async () => {
        try {
            const r = (await API.graphql({
                query: `
                    query isReceiptReceived {
                        isReceiptReceived {
                            pass
                            payload {
                            status
                            date
                            payload
                            report
                            }
                        }
                    }
 

                `,
            })) as any
            const ps=r.data.isReceiptReceived.payload
            setPayloads(ps.map((p:any)=>{
                return {...p, date:p.date.replaceAll('"','').replace('T',' ').split('.')[0]}
            }))
        } catch (e: any) {
            console.log(e.errors)
        }
    }
    const handlePayloadClick = (index:number) => {
        setSelected(index);
    }
    const completeStagingIntegration = async () => {
        if (!firstReceiptReceived) {
            await updateIntegrationStatus('receiptReceived', updateUserContext)
            navigate('/')
        }
        navigate('/')
    }
    const checkFirstReceiptReceived = async () => {
        try {
           setActualMenu('instructions');
            const r = (await API.graphql({
                query: `
                query isReceiptReceived {
                    isReceiptReceived {
                        pass
                        receipt {
                            amount
                            covers
                            currency
                            date
                            id
                            invoice
                            partnerName
                            items {
                                amount
                                itemName
                                quantity
                                taxAmount
                                taxDescription
                                taxRate
                                type
                            }
                            storeName
                            storeAddress
                        }
                    }
                }    

            `,
        })) as any
            console.log('R . DATA', r?.data)
            setFirstReceiptReceived(r.data.isReceiptReceived.pass)
            setReceiptReceived(r.data.isReceiptReceived.receipt)
            r.data.isReceiptReceived.pass &&
                updateIntegrationStatus('receiptReceived', updateUserContext)
            !r.data.isReceiptReceived.pass && setReceiptNotReceived(true)
            setTimeout(() => {
                setReceiptNotReceived(false);
            },5000);
            setIsLoading(false);
        } catch (e: any) {
            console.log(e.errors)
            setPayloadNotSent(true);
            setTimeout(() => {
                setPayloadNotSent(false);
            },5000)
            setIsLoading(false);
            setFirstReceiptReceived(false)
        }
    }

    useEffect(() => {
        loadPayloads()
    }, [])
    console.log('RECEIPT RECEIVED ID : ', receiptReceived);
    return (
        <GridPage>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" size={70} />
            </Backdrop>
            <GridPanelRow
                leftChildren={
                    <Box sx={{ paddingRight: 2 }}>
                        {firstReceiptReceived ? (
                            <Box sx={{ padding: 2 }}>
                                <PageTitle paddingBottom={5}>
                                Ticket envoyé avec succès 🎉
                                </PageTitle>
                                <SubTitle>
                                    Félicitations ! Vous êtes maintenant en mesure de demander un accès à l'environnement de production.
                                </SubTitle>
                                <SubTitle>
                                    L’équipe KillBills va revenir vers vous rapidement avec les différentes informations pour accéder à la production.
                                </SubTitle>
                            </Box>
                        ) : (
                            <Box sx={{ padding: 0 }}>
                                 { payloadNotSent ? <Alert sx={{width:'100%'}} type={'error'} label={'Payload non envoyée'} /> : '' }
                                 { receiptNotReceived ? <Alert sx={{width:'100%'}} type={'error'} label={'Ticket non reçu'} /> : '' }
                                <PageTitle paddingBottom={5}>
                                Envoyez un premier ticket 🧾
                                </PageTitle>
                                <Tabs
                                    tabs={tabs}
                                    onSelectElement={(x) => {
                                        setActualMenu(tabs[x].id)
                                        loadPayloads()
                                    }}
                                />
                                  {
                                actualMenu === 'instructions' ? 

                                <SubTitle>
                                En vous appuyant sur la documentation technique, envoyez un premier ticket à la plateforme KillBills. L'utilisation de la clé HMAC et de parter_name doit se faire comme à l'étape précédente. Veillez à correctement renseigner les champs requis de la payload.
                            
                                  <CopyField sx={{paddingBottom:2,paddingTop:2}} value={groups?.includes('bank') ? 'https://in.preprod.killbills.dev/transactions' : groups?.includes('pos') ? 'https://in.preprod.killbills.dev/receipt' : 'null'} label={groups?.includes('bank') ? 'API /transaction' : groups?.includes('pos') ? 'API /receipt' : 'null'} />
                                    <CopyField value={hmac} label={'Clé HMAC '} />
                                  </SubTitle>
                                :  <Box
                                sx={{
                                    display: 'block',
                                    maxWidth: {
                                        xs: '100%',
                                        sm: '100%',
                                        md: '100%',
                                        lg: '100%',
                                        xl: '100%',
                                    },
                                    marginBottom: 5,
                                    marginTop: 5,
                                }}
                            >
                                {payloads.map(
                                    (payload: any, index: number) => (
                                        <Payload
                                            key={index}
                                            highlight={
                                                selected === index
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                console.log(payload)
                                                handlePayloadClick(
                                                    index
                                                )
                                                setErrorPayloadToShow(
                                                    JSON.parse(
                                                        payload?.payload
                                                    )
                                                )
                                                setErrorReportToShow(
                                                    payload?.report
                                                )
                                                setErrorPayloadView(
                                                    true
                                                )
                                                return '1'
                                            }}
                                            status={
                                                payload.status === 'OK'
                                                    ? 'Status OK'
                                                    : 'Status KO'
                                            }
                                            timeStamp={payload.date}
                                        />
                                    )
                                )}
                            </Box>
                            }
                            </Box>
                        )}
                        <Grid
                            gap={2}
                            sx={{
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <Grid
                            gap={2}
                            sx={{
                                display: 'flex',
                                width: '100%',
                            }}>
                                <Button
                                sx={{
                                    marginTop:2,
                                    marginBottom: {
                                        xs: 1,
                                        sm: 1,
                                        md: 0,
                                        lg: 0,
                                        xl: 0,
                                    },
                                    marginRight: {
                                        xs: 0,
                                        sm: 0,
                                        md: 1,
                                        lg: 1,
                                        xl: 1,
                                    },
                                }}
                                    label={
                                        !firstReceiptReceived
                                            ? "J'ai envoyé la requête"
                                            : "Passer à la production"
                                    }
                                    variant="primary"
                                    onClick={() => {
                                        setIsLoading(true);
                                        !firstReceiptReceived
                                            ? checkFirstReceiptReceived()
                                            : completeStagingIntegration()
                                    }}
                                />
                                {
                                    actualMenu === 'payloads' &&   <Button
                                    sx={{
                                        marginTop:2,
                                    marginBottom: {
                                        xs: 1,
                                        sm: 1,
                                        md: 0,
                                        lg: 0,
                                        xl: 0,
                                    },
                                    marginRight: {
                                        xs: 0,
                                        sm: 0,
                                        md: 1,
                                        lg: 1,
                                        xl: 1,
                                    },
                                    }}
                                        label={'Recharger la liste des payloads'}
                                        variant="primary"
                                        onClick={() => {
                                            setIsLoading(true);
                                            loadPayloads();
                                            setIsLoading(false);
                                        }}
                                    />
                                }
                                
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                paddingTop: 2,
                                paddingBottom: 2,
                                width: '100%',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'column',
                                    md: 'row',
                                    lg: 'row',
                                    xl: 'row',
                                },
                            }}
                        >
                            {showTestPayload && (
                                <Box
                                    sx={{
                                        maxWidth: '100%',
                                        backgroundColor: '#3C3A56',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <JsonDisplay
                                        Json={JSON.stringify(
                                            testPayload,
                                            null,
                                            4
                                        )}
                                    />{' '}
                                </Box>
                            )}
                        </Grid>
                    </Box>
                }
                rightChildren={
                    errorPayloadView && actualMenu === 'payloads' ? (
                        <Grid
                        container
                        direction={'column'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        alignContent={'center'}
                        height={'100%'}
                    >
                        {errorReportToShow && (
                            <Chip color="error" label={errorReportToShow} />
                        )}
                        <CRD sx={{backgroundColor:'transparent',overflowY:'scroll',height:500,padding:2}}>
                        <JsonDisplay
                            Json={JSON.stringify(
                                errorPayloadToShow,
                                null,
                                4
                            )}
                        />
                        </CRD>
                    </Grid>
                    ) : 
                    <Card
                        timeLine
                        sx={{
                            width: '40vw',
                            height: '100%',
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'flex',
                                lg: 'flex',
                                xl: 'flex',
                            },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {firstReceiptReceived ? (
                            <ReceiptReceived
                                sx={{ width: 300, height: 400, zIndex: 2 }}
                                amount={receiptReceived?.amount}
                                date={new Date(
                                    parseInt(receiptReceived?.date)
                                ).toLocaleDateString('fr-FR')}
                                partnerLogo={''}
                                storeName={receiptReceived?.storeName}
                                storeAddress={receiptReceived?.storeAddress}
                                items={receiptReceived?.items}
                                partnerName={receiptReceived?.partnerName}
                                id={receiptReceived?.id}
                            />
                        ) : (
                            <ReceiptReceived
                                skeleton
                                sx={{ width: 300, height: 400, zIndex: 2 }}
                                amount={receiptReceived?.amount}
                                date={new Date(
                                    parseInt(receiptReceived?.date)
                                ).toLocaleDateString()}
                                partnerLogo={''}
                                storeName={receiptReceived?.storeName}
                                storeAddress={receiptReceived?.storeAddress}
                                items={receiptReceived?.items}
                                partnerName={receiptReceived?.partnerName}
                                id={receiptReceived?.id}
                            />
                        )}
                    </Card>
                }
            />
        </GridPage>
    )
}
